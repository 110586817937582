import { customFunctions } from "@/config/customFunction";
import { useCreateLot } from "@/hooks/useCreateLot";
import { AuthSignupPost201ResponseEquipmentPurchaseOrderItem, PurchaseOrdersGet200ResponseRowsInnerPurchaseOrderItemsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useEffect, useMemo } from "react";


type useCreatePurchaseOrderItemsWithLotProps = {
    items: (PurchaseOrdersGet200ResponseRowsInnerPurchaseOrderItemsInner[] | undefined)
    | (AuthSignupPost201ResponseEquipmentPurchaseOrderItem[] | undefined)
}


export const useCreatePurchaseOrderItemsWithLot = ({ items }: useCreatePurchaseOrderItemsWithLotProps) => {

    const { createLot, createdLotInform } = useCreateLot()

    const updatedPurchaseOrderItemsWithLot = useMemo(() => {
        if (createdLotInform.length > 0) {
            return items?.map((item) => ({
                ...item,
                lotName: createdLotInform?.find((lot) => lot.itemCode === item.itemCode)?.name,
                lotId: createdLotInform?.find((lot) => lot.itemCode === item.itemCode)?.id,
                lotInfo: {
                    name: createdLotInform?.find((lot) => lot.itemCode === item.itemCode)?.name,
                    expiration: createdLotInform?.find((lot) => lot.itemCode === item.itemCode)?.expiration
                }
            }));
        }
    }, [createdLotInform]);


    useEffect(() => {
        if (items && customFunctions.ADD_ORDER_AUTO_CREATE_LOT) {
            const itemCodeList = items?.map(item => item.itemCode) as string[]
            createLot(itemCodeList)
        }
    }, [])

    return { updatedPurchaseOrderItemsWithLot }

}