import DefaultInstance from "@/instance/axios";
import { createMutationKeys, createQueryKeys, mergeQueryKeys } from "@lukemorales/query-key-factory";
import { DefaultApiWmsLogsGetRequest, DefaultApiWmsLogsPostRequest, DefaultApiWmsLogsWmsLogIdGetRequest } from "@sizlcorp/sizl-api-document/dist/models";

export const wmsLog = createQueryKeys('wmsLog', {
    all: null,
    get: (params: DefaultApiWmsLogsGetRequest) => {
        // const queryParams = { ...params };
        const queryParams = { ...params, query: JSON.stringify({ "$and": params?.query }), sort: params.sort?.length ? params.sort : "-id", }; // wmsLog 조회 시 기본 정렬 표시

        return {
            queryKey: [queryParams, JSON.stringify(queryParams)],
            queryFn: () => DefaultInstance.wmsLogsGet(queryParams),
        }
    },
    detail: (params: DefaultApiWmsLogsWmsLogIdGetRequest) => {
        return {
            queryKey: [params.wmsLogId],
            queryFn: () => DefaultInstance.wmsLogsWmsLogIdGet(params)
        }
    }
})

export const mutateWmsLog = createMutationKeys('wmsLog', {
    create: (params: DefaultApiWmsLogsPostRequest) => ({
        mutationKey: [params.wmsLogsGetRequest],
        mutationFn: () => DefaultInstance.wmsLogsPost(params),
    }),
})

export const wmsLogQuery = mergeQueryKeys(wmsLog);