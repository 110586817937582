import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { MantineReactCommonTable } from "@/features/ui/mantineTable";
import { setToLocaleString } from "@/utils/unitMark";
import { InvoicesGet200ResponseRowsInner, InvoicesGet200ResponseRowsInnerInvoiceItemsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";
import dayjs from "dayjs";
import { MRT_ColumnDef, MRT_PaginationState } from "mantine-react-table";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";

interface InvoiceStatementDetailTableProps {
    data: AxiosResponse<InvoicesGet200ResponseRowsInner, any> | undefined;
    rowSelection: Record<string, boolean>;
    setRowSelection: Dispatch<SetStateAction<Record<string, boolean>>>;
}

export const InvoiceStatementDetailTable = (params: InvoiceStatementDetailTableProps) => {

    const { data, rowSelection, setRowSelection } = params;

    const { openModal } = useModal();

    const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 1,
        pageSize: 10,
    });

    useEffect(() => {
        const ids = Object.keys(rowSelection).filter((key) => rowSelection[key]);
        setSelectedRowIds(ids);
    }, [rowSelection]);

    const columns = useMemo<MRT_ColumnDef<InvoicesGet200ResponseRowsInnerInvoiceItemsInner>[]>(
        () => [
            {
                accessorFn: (row) => row.id,
                accessorKey: 'id',
                header: '거래명세서 번호',
                size: 80,
                enableColumnFilter: false, // id는 검색 못하게
                Cell: (rows) => {
                    return (
                        <div style={{ textAlign: 'right' }}>{rows.row._valuesCache.id}</div>
                    )
                }
            },
            {
                accessorFn: (row) => row.code,
                accessorKey: 'code',
                header: '거래명세서 코드',
            },
            {
                accessorFn: (row: any) =>
                    <DetailLink
                        onClick={() => {
                            openModal(
                                <ItemsDetailForm itemCode={row.itemCode} />,
                                null,
                                "품목 상세"
                            )
                        }}
                        justify={"flex-start"}
                    >
                        {row.itemCode}
                    </DetailLink>,
                accessorKey: 'itemCode',
                header: '품목코드',
                size: 80,
            },
            {
                accessorFn: (row) =>
                    <DetailLink
                        onClick={() => {
                            openModal(
                                <LocationDetailForm locationCode={row.locationCode} />,
                                null,
                                "로케이션 상세"
                            )
                        }}
                        justify={"flex-start"}
                    >
                        {row.locationCode}
                    </DetailLink>,
                accessorKey: 'locationCode',
                header: '출고 로케이션',

            },
            {
                accessorFn: (row) => row.quantity,
                accessorKey: 'quantity',
                header: '수량',
                enableColumnFilter: false,
                size: 80,
                Cell: (rows) => {
                    const quantity = setToLocaleString(rows.row._valuesCache.quantity) === "NaN" ? '0' : setToLocaleString(rows.row._valuesCache.quantity);
                    return (
                        <div style={{ textAlign: 'right' }}>{quantity}</div>
                    )
                }
            },
            {
                accessorFn: (row) => row.outgoingQuantity,
                accessorKey: 'outgoingQuantity',
                header: '출고 수량',
                enableColumnFilter: false,
                size: 80,
                Cell: (rows) => {
                    const outgoingQuantity = setToLocaleString(rows.row._valuesCache.outgoingQuantity) === "NaN" ? '0' : setToLocaleString(rows.row._valuesCache.outgoingQuantity);
                    return (
                        <div style={{ textAlign: 'right' }}>{outgoingQuantity}</div>
                    )
                }
            },
            {
                accessorFn: (row) => row.invoiceDate,
                accessorKey: 'invoiceDate',
                header: '거래명세서일',
                enableColumnFilter: false,
                size: 120,
            },
            {
                accessorFn: (row) => dayjs(row.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                accessorKey: 'createdAt',
                header: '생성일',
                enableColumnFilter: false,
                size: 200,
            }
        ],
        [],
    );

    return (
        <MantineReactCommonTable
            data={data?.data?.invoiceItems ?? []}
            columns={columns}
            totalCount={10}
            totalPages={1}
            pagination={pagination} // pagination
            setPagination={setPagination}
            rowSelection={rowSelection} // 선택
            setRowSelection={setRowSelection}
            selectedRowIds={selectedRowIds} // 선택된 id
            setSelectedRowIds={setSelectedRowIds}
            enableRowSelection={true}
            // sorting={sorting} // 정렬
            // setSorting={setSorting}
            // columnFilters={columnFilters} // 컬럼별 검색
            // setColumnFilters={setColumnFilters}
            // globalFilter={globalFilter} // 전체 검색
            // setGlobalFilter={setGlobalFilter}
            enableTopToolbar={true}
        />
    )
}