import { mutatePurchaseOrders } from "@/api/purchaseOrder/usePurchaseOrder";
import { customFunctions } from "@/config/customFunction";
import { useModal } from "@/features/modal/ModalStackManager";
import { useStandardLayout } from "@/features/standardLayout/Context";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { useLoader } from "@/hooks/useLoader";
import { getUserCodeByUser } from "@/utils/checkData";
import { Button, Flex } from "@mantine/core";
import { PurchaseOrdersGet200Response, PurchaseOrdersGet200ResponseRowsInner, ScmApiPurchaseOrdersPurchaseOrderIdDeleteRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconPlus, IconTrash } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { OrderCreateForm } from "../form/createForm";
import { OrderItemsIncomingForm } from "../form/orderItemsIncomingForm";
import { OrderItemsIncomingProps } from "../hook/useOrderItemsIncoming";

export const OrderActionHeader = ({setLoading}: OrderItemsIncomingProps) => {
    const { openModal, closeModal } = useModal();
    const userCode = getUserCodeByUser();
    const { selectedRows, refetch, data, setSelectedRows } = useStandardLayout();
    const { LoadingOverlay } = useLoader();

    const setData = (data as AxiosResponse<PurchaseOrdersGet200Response, any>)?.data?.rows
        ?.filter((item) => selectedRows.has(item.id))
        ?.map((item) => item);

    const { mutate: deleteMutate } = useMutation(
        (params: ScmApiPurchaseOrdersPurchaseOrderIdDeleteRequest) =>
            mutatePurchaseOrders.delete(params).mutationFn(params as any | ScmApiPurchaseOrdersPurchaseOrderIdDeleteRequest),
        {
            onSuccess: () => {
                refetch();
                customAlert(
                    `${userCode === "FLOUR" ? "수주 삭제에 성공하였습니다." : "발주 삭제에 성공하였습니다."}`,
                    "삭제 성공",
                    "green"
                );
                closeModal(null);
            },
            onError: (error: any) => {
                customAlert(
                    `${error.response?.data?.message}` ?? `${userCode === "FLOUR" ? "수주 삭제에 실패하였습니다." : "발주 삭제에 실패하였습니다."}`,
                    "삭제 실패",
                    "red"
                );
            },
        }
    );
    const handleDelete = () => {
        if ([...selectedRows].length === 0) {
            customAlert("삭제할 발주를 선택해주세요.", "삭제 실패", "red");
            return;
        }

        openModal(
            <ConfirmForm
                message="선택한 행을 삭제하시겠습니까?"
                yesCallback={() => {
                    [...selectedRows].forEach((row) => {
                        deleteMutate({ purchaseOrderId: row });
                    });

                    selectedRows.clear();
                }
                }
                noCallback={() => { }}
                commonCallback={() => closeModal(null)}
            />,
            null,
            "발주 삭제",
        )
    }
    return (
        <Flex w="100%" justify="space-between" wrap="wrap">
            <LoadingOverlay />
            <Flex gap="sm" justify="flex-start" p="sm">
                <Button
                    onClick={() => openModal(
                        <OrderCreateForm />,
                        null,
                        `${userCode === "FLOUR" ? '수주 추가' : '발주 추가'}`,
                        true)}
                    leftIcon={<IconPlus />}
                >
                    추가
                </Button>
                <Button
                    leftIcon={<IconPlus />}
                    color="orange"
                    disabled={[...selectedRows].length !== 1}
                    onClick={() => openModal(
                        <OrderItemsIncomingForm purchaseOrder={setData && setData[0] as PurchaseOrdersGet200ResponseRowsInner} setLoading={setLoading} />,
                        null,
                        "입고",
                    ).then((res) => {
                        if (res) {
                            setSelectedRows((): Set<any> => new Set());
                        }
                    })}
                >
                    입고
                </Button>
                {customFunctions.ADD_ORDER_ACTION_BUTTON ? (
                    <></>
                ) : (
                    <Flex gap="sm">
                        <Button
                            rightIcon={<IconTrash />}
                            color="red"
                            onClick={() => handleDelete()}
                        >
                            선택된 행 삭제
                        </Button>
                    </Flex>
                )}
            </Flex>
        </Flex>

    )
}