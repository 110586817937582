import { invoice, mutateInvoice } from "@/api/invoiceStatement/useInvoiceQuery";
import { LeftSpace } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { ActionButtonBox } from "@/features/standard/ActionButtonBox";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { DetailContainer } from "@/features/standard/detail/Container";
import { DetailContent } from "@/features/standard/detail/Content";
import { DetailWrapper } from "@/features/standard/detail/Wrapper";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { useLoader } from "@/hooks/useLoader";
import { handleErrorResponse } from "@/utils/errorMessage";
import { Button, Tabs } from "@mantine/core";
import { DefaultApiWmsBulkInvoicePostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useState } from "react";
import { InvoiceStatementDetailHeader } from "./detailHeader";
import { InvoiceStatementDetailTable } from "./detailTable";

interface InvoiceStatementItemsSelectFormProps {
    invoiceId: number | undefined;
    selectedRows: Set<any>;
    setSelectedRows: Dispatch<SetStateAction<Set<any>>>;
}

const Detail = Object.assign({}, {
    Container: DetailContainer,
    Wrapper: DetailWrapper,
    Content: DetailContent,
    ActionHeader: ActionHeader,
    ActionButtonBox: ActionButtonBox,
    ActionButton: Button,
    ActionBar: InvoiceStatementDetailHeader
})

export const InvoiceStatementDetailForm = (params: InvoiceStatementItemsSelectFormProps) => {
    const { invoiceId, selectedRows, setSelectedRows } = params;
    const { openModal, closeModal } = useModal();
    const queryClient = useQueryClient();
    const { LoadingOverlay, setLoading } = useLoader();
    const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
    
    const { mutate: invoiceMutate } = useMutation(
        (params: DefaultApiWmsBulkInvoicePostRequest) =>
            mutateInvoice.bulkInvoice(params).mutationFn(params as DefaultApiWmsBulkInvoicePostRequest | any),
        {
            onMutate: () => {
                setLoading(true);
            },
            onSuccess: (res) => {
                const success = res.data?.success;
                const failed = res.data?.fail;

                setLoading(false);
                queryClient.invalidateQueries(["invoice"]);
                closeModal(null);
                setSelectedRows(new Set());
                customAlert(
                    `${success?.length ? `거래명세서 ID ${success}이 출고에 성공하였습니다.` : ""} 
                    ${failed?.length ? `거래명세서 ID ${failed}이 출고에 실패하였습니다.` : ""}`,
                    "거래명세서 출고 성공",
                    "green"
                )
            },
            onError: (error: any) => {
                setLoading(false);
                customAlert(
                    handleErrorResponse({ error, errorContext: "거래명세서 출고" }), "거래명세서 출고 실패", "red"
                )
            }
        }
    )
    const { data } = useQuery(invoice.detail({
        invoiceId: invoiceId ?? 0,
        populate: ["populateLocationName", "populateUserName", "populateNumberOfInvoiceItems", "invoiceItems"],
    }))

    const invoiceOutgoingSubmit = () => {
        const arrayInvoiceId = [...selectedRows].map(row => JSON.parse(row).id);
        const selectedRowsArray = [...selectedRows].map(row => JSON.parse(row));
        const invoiceNos = selectedRowsArray
            .filter(row => arrayInvoiceId.includes(row.id))
            .map(row => row.invoiceNo);

        openModal(
            <ConfirmForm
                message={`선택한 거래명세서ID ${arrayInvoiceId}를 출고하시겠습니까?`}
                yesCallback={() => {
                    invoiceMutate({
                        wmsBulkInvoicePostRequest: {
                            invoiceNos: invoiceNos
                        }
                    });
                }}
                noCallback={() => { closeModal(null) }}
                commonCallback={() => { }}
            />,
            null,
            "거래명세서 출고"
        )
    }

    const statusDone = [...selectedRows].some(row => {
        const parsedRow = JSON.parse(row);
        return parsedRow.status === "DONE";
    });

    return (
        <Detail.Container>
            <LoadingOverlay />
            <Detail.Wrapper>
                <Detail.Content>
                    {/* <OrderDetailDataHeader /> */}
                    <InvoiceStatementDetailHeader data={data?.data} />
                    <Tabs defaultValue={'invoiceItems'}>
                        <LeftSpace />
                        <Tabs.List>
                            <Tabs.Tab value={'invoiceItems'}>거래명세서 항목</Tabs.Tab>
                        </Tabs.List>
                        <Tabs.Panel value={"invoiceItems"}>
                            {/* <Detail.ActionHeader>
                                <Detail.ActionButtonBox>
                                    <Detail.ActionButton
                                        leftIcon={<IconMinus />}
                                        color="red"
                                        onClick={invoiceOutgoingSubmit}
                                        disabled={!([...rowSelection].length >= 1) || statusDone}
                                    >출고</Detail.ActionButton>
                                </Detail.ActionButtonBox>
                            </Detail.ActionHeader> */}
                            <InvoiceStatementDetailTable data={data} rowSelection={rowSelection} setRowSelection={setRowSelection} />
                        </Tabs.Panel>
                    </Tabs>
                </Detail.Content>
            </Detail.Wrapper>
        </Detail.Container >
    )

}