import { QueryType, useStandardLayout } from "@/features/standardLayout/Context";
import { handleKeyDown } from "@/utils/event";
import { Button, Flex, Select, SelectItem, TextInput } from "@mantine/core";
import { InvoicesGet200ResponseRowsInnerInvoiceTypeEnum } from "@sizlcorp/sizl-api-document/dist/models";
import { useEffect, useRef, useState } from "react";

const pageSizeOptions = [
    { value: '5', label: "5개씩" },
    { value: '10', label: "10개씩" },
    { value: '15', label: "15개씩" },
    { value: '20', label: "20개씩" },
]

const invoiceTypeOptions: SelectItem[] = [
    { value: JSON.stringify(Object.values(InvoicesGet200ResponseRowsInnerInvoiceTypeEnum)), label: '모두' },
    ...Object.entries(InvoicesGet200ResponseRowsInnerInvoiceTypeEnum).map(([key, value]) => ({
        value: value as string,
        label: key === 'DOMESTIC' ? '국내' : key === 'INTERNATIONAL' ? '해외' : key,
    }))
];

export const InvoiceSearchBox = () => {
    const { setPage, pageSize, setPageSize, setSelectedRows, searchFields, setSearchFields, search, setSearch, searchFieldsHeader, setQuery } = useStandardLayout();

    const [selectedInvoiceType, setSelectedInvoiceType] = useState<string>(invoiceTypeOptions[0].value);
    const [pageSizeState, setPageSizeState] = useState(pageSize);
    const [searchFieldsState, setSearchFieldsState] = useState(searchFields);
    
    const searchRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (searchRef.current) {
            searchRef.current.value = search;
        }
        setPageSizeState(pageSize);
        setSearchFieldsState(searchFields);
    }, [pageSize, search, searchFields]);

    const onSubmit = () => {
        setPage(1); // 검색어 변경 시 항상 페이지를 1로 초기화
        setSelectedRows((): Set<any> => new Set()); // 검색어 변경 시 항상 선택된 행 초기화
        setPageSize(pageSizeState);
        setSearchFields(searchFieldsState);
        setSearch(searchRef.current?.value || "");
    }

    const handleSelectChange = (value: string | null) => {
        // '모두'를 선택한 경우 처리
        const parsedValue = value && value.startsWith('[') ? JSON.parse(value) : value;
    
        // '모두'인 경우 '$in' 쿼리 생성, 그렇지 않으면 '$eq'
        setQuery((prev: QueryType[]) => [
            parsedValue && Array.isArray(parsedValue)
                ? { invoiceType: { "$in": parsedValue } }
                : { invoiceType: { "$eq": parsedValue } }
        ]);
        
        setSelectedInvoiceType(value ?? invoiceTypeOptions[0].value);
    };
    
    return (
        <Flex gap="sm">
            <Select
                data={pageSizeOptions}
                value={pageSizeState.toString()}
                onChange={(e) => setPageSizeState(Number(e))}
            />
            <Select 
                data={invoiceTypeOptions}
                placeholder="거래명세서 타입 선택"
                onChange={handleSelectChange}
                value={selectedInvoiceType}
            />
            <Select
                data={searchFieldsHeader.filter((item) => item.category === "text" && !item.isEnum)}
                value={searchFieldsState.toString()}
                placeholder="통합 검색"
                onChange={(e) => setSearchFieldsState(e ? [e] : [])}
            />
            <TextInput
                datatype="text"
                defaultValue={search}
                placeholder="검색어를 입력하세요"
                ref={searchRef}
                onKeyDown={(e) => handleKeyDown(e, onSubmit)}
            />
            <Button onClick={() => onSubmit()}>검색</Button>
        </Flex>
    )
}