import { useContext } from "react";
import { LotTrackingActionsContext } from "../context/LotTrackingProvider";

export function useLotTrackingActions() {
  const context = useContext(LotTrackingActionsContext);
  if (context === undefined) {
    throw new Error("useLotTrackingActions must be used within a WorkProvider");
  }
  return context;
}
