import { useContext } from "react";
import { LotTrackingContextProps, LotTrackingStateContext } from "../context/LotTrackingProvider";

export function useLotTrackingState(): LotTrackingContextProps {
  const context = useContext(LotTrackingStateContext);
  if (context === undefined) {
    throw new Error("useLotTrackingState must be used within a WorkProvider");
  }
  return context;
}
