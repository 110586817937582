import { lots } from "@/api/lots/useLotsQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { LotSelectComplete } from "@/features/ui/autoComplete/lot/lot-\bselectComplete";
import styled from "@emotion/styled";
import { ItemsGet200ResponseRowsInnerItemTypeEnum } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { useLotTrackingActions } from "../../hook/useLotTrackingActions";
import { useLotTrackingState } from "../../hook/useLotTrackingState";

export const LotTrackingForwardActionHeader = () => {

    const { openModal } = useModal();
    const actions = useLotTrackingActions();
    const state = useLotTrackingState();
    let tempLotId = "";

    const { data: lotData } = useQuery(lots.get(
        {
            query: { $and: [{ itemCode: { $eq: state.itemCodeValue } }] }
        }
    ));

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            actions.setLotId(tempLotId);
        } else {
            tempLotId += e.key;
        }
    };
    
    return (
        <ActionBarContainer>
            <LeftActionBar>
                <ItemAutoComplete
                    placeholder="품목을 선택해주세요"
                    variant="filled"
                    getItemType={[ItemsGet200ResponseRowsInnerItemTypeEnum.MATERIAL, ItemsGet200ResponseRowsInnerItemTypeEnum.SEMI_PRODUCT, ItemsGet200ResponseRowsInnerItemTypeEnum.SUBSIDIARY]}
                    style={{ flex: 1 }}
                    onChange={(e) =>  actions.setItemCodeValue(e ?? '')}
                    value={state.itemCodeValue}
                />
                <LotSelectComplete
                    placeholder="로트를 선택해주세요"
                    style={{ flex: 1 }}
                    variant="filled"
                    itemCodeValue={state.itemCodeValue ?? ''}
                    onChange={(e) => actions.setLotId(e ?? '')}
                    value={state.lotId}
                />
            </LeftActionBar>
            {/* <RightActionBar>
                <Input
                    placeholder="바코드를 찍어주세요"
                    value={lotId as string}
                    onKeyDown={handleKeyDown}
                    data-autofocus
                />
                <Button
                    onClick={() =>
                        openModal("", "", "")
                    }
                >
                    추적
                </Button>
            </RightActionBar> */}
        </ActionBarContainer>
    );
};

const ActionBarContainer = styled.div`
  display: flex;
  padding: 8px 0;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  row-gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
  background: inherit;
`;

const LeftActionBar = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

const RightActionBar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
`;
