
import { customFunctions } from "@/config/customFunction";
import { useModal } from "@/features/modal/ModalStackManager";
import { OrderItemType } from "@/features/order/form/orderItemsIncomingForm";
import { useOrderItemsIncoming } from "@/features/order/hook/useOrderItemsIncoming";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { FormActionButtonGroup } from "@/features/ui/formActionButtonGroup/FormActionButtonGroup";
import { NewLotCreateInput } from "@/features/ui/newLotCreateInput/NewLotCreateInput";
import { MantineSelectableTable } from "@/features/ui/table/MantineSelectableTable";
import { useCreatePurchaseOrderItemsWithLot } from "@/hooks/useCreatePurchaseOrderItemsWithLot";
import { Flex, Space, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { AuthSignupPost201ResponseEquipmentPurchaseOrderItem, ItemsGet200ResponseRowsInnerLotsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { Dispatch, SetStateAction, useEffect } from "react";

interface PurchaseOrderItemsSelectFormProps {
    purchaseOrder: AuthSignupPost201ResponseEquipmentPurchaseOrderItem | undefined;
    setLoading: Dispatch<SetStateAction<boolean>>
}

const DeliveryIncoming = Object.assign(
    {},
    {

        TextInput: TextInput,
        NewLotCreateInput: NewLotCreateInput,
        LocationAutoComplete: LocationAutoComplete,
        FormActionButtonGroup: FormActionButtonGroup,
        MantineSelectableTable: MantineSelectableTable,
    }
);

export const DeliveryIncomingForm = ({ purchaseOrder, setLoading }: PurchaseOrderItemsSelectFormProps) => {

    const { closeModal } = useModal();
    const { orderItemsSubmit, isLoading } = useOrderItemsIncoming({setLoading});

    const { updatedPurchaseOrderItemsWithLot } = useCreatePurchaseOrderItemsWithLot({ items: [purchaseOrder as AuthSignupPost201ResponseEquipmentPurchaseOrderItem] });

    const form = useForm<OrderItemType>({
        initialValues: {
            ...purchaseOrder,
            lotId: undefined,
            lotInfo: undefined,
        },
    });

    const usesLotName = form.values.usesLotName;
    const usesLotExpiration = form.values?.usesLotExpiration;
    const isLotCreationEnabled = !usesLotName && !usesLotExpiration;

    const lotDescription = (() => {
        if (usesLotName && usesLotExpiration) return "이름 + 만료일";
        if (usesLotName) return "이름";
        if (usesLotExpiration) return "만료일";
        return "관리 안함";
    })();

    const handleLotCreation = (data: ItemsGet200ResponseRowsInnerLotsInner) => {
        form.setValues(
            {
                ...form.values,
                lotId: data?.id as number,
                lotInfo: {
                    name: data?.name,
                    expiration: data?.expiration,
                },
            },
        );
    };

    const onSubmit = () => {
        orderItemsSubmit(form.values)
        if(!isLoading) closeModal({});
    }

    useEffect(() => {
        if (updatedPurchaseOrderItemsWithLot?.[0].lotId) {
            form.setValues({
                ...form.values,
                lotId: updatedPurchaseOrderItemsWithLot?.[0]?.lotId,
                lotInfo: updatedPurchaseOrderItemsWithLot?.[0]?.lotInfo
            })
        }
    }, [updatedPurchaseOrderItemsWithLot])

    return (
        <>
            {
                <Flex direction={'column'} gap={30}>
                    <DeliveryIncoming.TextInput
                        disabled
                        label="품목코드"
                        withAsterisk
                        value={form.values.itemCode}
                    />
                    {
                        customFunctions.ADD_ORDER_AUTO_CREATE_LOT ?
                            <DeliveryIncoming.TextInput
                                disabled
                                label="LOT 정보"
                                withAsterisk
                                value={updatedPurchaseOrderItemsWithLot?.[0]?.lotName}
                            /> :
                            <DeliveryIncoming.NewLotCreateInput
                                lotDescription={lotDescription}
                                disabled={isLotCreationEnabled}
                                itemCode={form.values.itemCode as string}
                                newIdValue={form.values.lotId as number}
                                defaultValue={form.values.lotId?.toString() ?? ""}
                                onLotCreated={handleLotCreation}
                                {...form.getInputProps("lotId")}
                            />
                    }
                    <DeliveryIncoming.LocationAutoComplete
                        label="로케이션코드"
                        withAsterisk
                        defaultValue={purchaseOrder?.targetLocationCode}
                        {...form.getInputProps("locationCode")}
                    />
                    <DeliveryIncoming.TextInput
                        label="수량"
                        withAsterisk
                        type="number"
                        {...form.getInputProps("quantity")}
                    />
                    {
                        !customFunctions.ADD_ORDER_AUTO_CREATE_LOT &&
                        <DeliveryIncoming.TextInput
                            disabled
                            label="만료일"
                            value={form?.values?.lotInfo?.expiration}
                        />
                    }
                    <Space h="xl" />
                    <DeliveryIncoming.FormActionButtonGroup
                        form={form}
                        onCancel={() => closeModal({})}
                        onSubmit={onSubmit}
                    >
                        {'입고'}
                    </DeliveryIncoming.FormActionButtonGroup>
                </Flex>
            }
        </>
    )
}