import { items } from "@/api/items/useItemQuery"
import { itemTypeColor } from "@/constants/items"
import { DetailContent } from "@/features/ui/detail/DetailContent"
import { DetailField } from "@/features/ui/detail/DetailField"
import { DetailHeader } from "@/features/ui/detail/DetailHeader"
import { DetailLabel } from "@/features/ui/detail/DetailLabel"
import { DetailTitle } from "@/features/ui/detail/DetailTitle"
import styled from "@emotion/styled"
import { Badge } from "@mantine/core"
import { ItemsGet200ResponseRowsInnerItemTypeEnum } from "@sizlcorp/sizl-api-document/dist/models"
import { useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { useLotTrackingState } from "../../hook/useLotTrackingState"

const ItemContent = Object.assign(DetailHeader, {
    Title: DetailTitle,
    Field: DetailField,
    Label: DetailLabel,
    Content: DetailContent,
})

export const LotTrackingReverceItemContent = () => {
    const { t } = useTranslation();
    const state = useLotTrackingState();

    const { data } = useQuery({
        ...items.get({
            query: { $and: [{ code: { $eq: state.itemCodeValue } }] }
        }),
        enabled: !!state.itemCodeValue
    })

    const itemData = data?.data?.rows && data?.data?.rows[0]

    return (
        <Container>
            <ItemContent.Title>{itemData?.name}</ItemContent.Title>
            <ItemContent.Field>
                <ItemContent.Label>타입</ItemContent.Label>
                <ItemContent.Content>
                    <Badge size="lg" color={itemTypeColor[itemData?.itemType as ItemsGet200ResponseRowsInnerItemTypeEnum ?? ""]}>
                        {t(itemData?.itemType ?? '')}
                    </Badge>
                </ItemContent.Content>
            </ItemContent.Field>
            <ItemContent.Field>
                <ItemContent.Label>코드</ItemContent.Label>
                <ItemContent.Content>{itemData?.code}</ItemContent.Content>
            </ItemContent.Field>
            <ItemContent.Field>
                <ItemContent.Label>분류</ItemContent.Label>
                <ItemContent.Content>{itemData?.abstractItemCode}</ItemContent.Content>
            </ItemContent.Field>
            {/* <ItemContent.Field>
                    <ItemContent.Label>품목리스트</ItemContent.Label>
                    <ItemContent.Content>품목리스트</ItemContent.Content>
                </ItemContent.Field> */}
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    padding: 16px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    background: var(--gray-0, #F8F9FA);
`