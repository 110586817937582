import { customFunctions } from "@/config/customFunction";
import { ScmInstance } from "@/instance/axios";
import { getUserCodeByUser } from "@/utils/checkData";
import { createMutationKeys, createQueryKeys } from "@lukemorales/query-key-factory";
import { ScmApiPurchaseOrdersFindPostRequest, ScmApiPurchaseOrdersGetRequest, ScmApiPurchaseOrdersPostRequest, ScmApiPurchaseOrdersPurchaseOrderIdDeleteRequest, ScmApiPurchaseOrdersPurchaseOrderIdGetRequest, ScmApiPurchaseOrdersPurchaseOrderIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";

export const purchaseOrders = createQueryKeys('purchaseOrders', {
    all: null,
    get: (params: ScmApiPurchaseOrdersGetRequest) => {
        const query = { ...params }; // params 객체를 복사합니다.
        // params.query.$and가 배열이고 길이가 0보다 클 때만 query.query를 설정합니다.
        if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
        // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }
        return {
            queryKey: [query, JSON.stringify(query.query)],
            queryFn: () => ScmInstance.purchaseOrdersGet(query),
        };
    },
    detail: (params: ScmApiPurchaseOrdersPurchaseOrderIdGetRequest) => ({
        queryKey: [params.purchaseOrderId],
        queryFn: () => ScmInstance.purchaseOrdersPurchaseOrderIdGet(params)
    }),
    find: (params: ScmApiPurchaseOrdersFindPostRequest) => {
        const query = { ...params }; // params 객체를 복사합니다.
        // params.query.$and가 배열이고 길이가 0보다 클 때만 query.query를 설정합니다.
        if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
        // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }
        return {
            queryKey: [query, JSON.stringify(query.query)],
            queryFn: () => ScmInstance.purchaseOrdersFindPost(query),
        };
    },
    order: (params: ScmApiPurchaseOrdersGetRequest) => {
        const userAuth = customFunctions.ADD_ORDER_USER_AUTH;
        const userCode = getUserCodeByUser()

        const queryParams = {
            ...params,
            query: JSON.stringify({
                "$and": [
                    ...(params?.query || []),
                ],
                direction: {
                    $eq: "OUTBOUND"
                },
                status: {
                    $ne: "DONE"
                },
                ...(userAuth ? {} : {
                    $or: [
                        { creatorUserCode: userCode },
                        { creatorUserCode: "FLOUR" }, // 추후 수정 필요
                        { partnerCode: userCode }
                    ]
                })
            }),
            sort: params.sort?.length ? params.sort : "-createdAt",
        };; // 재고 조회 시 기본 정렬 표시
        return {
            queryKey: [queryParams, JSON.stringify({ ...queryParams })],
            queryFn: () => ScmInstance.purchaseOrdersGet(queryParams),
        };
    },
    complete: (params: ScmApiPurchaseOrdersGetRequest) => {
        const queryParams = {
            ...params,
            query: JSON.stringify({
                "$and": [
                    ...(params?.query || []),
                ],
                status: {
                    $eq: "DONE",
                },
                direction: {
                    $eq: "OUTBOUND"
                },
            }),
            sort: params.sort?.length ? params.sort : "-createdAt",
        };; // 재고 조회 시 기본 정렬 표시
        return {
            queryKey: [queryParams, JSON.stringify({ ...queryParams })],
            queryFn: () => ScmInstance.purchaseOrdersGet(queryParams),
        };
    },
});

export const mutatePurchaseOrders = createMutationKeys('purchaseOrders', {
    create: (params: ScmApiPurchaseOrdersPostRequest) => ({
        mutationKey: [params.purchaseOrdersGetRequest],
        mutationFn: () => ScmInstance.purchaseOrdersPost(params),
    }),
    update: (params: ScmApiPurchaseOrdersPurchaseOrderIdPutRequest) => ({
        mutationKey: [params.purchaseOrderId, params.purchaseOrdersGetRequest],
        mutationFn: () => ScmInstance.purchaseOrdersPurchaseOrderIdPut(params)
    }),
    delete: (params: ScmApiPurchaseOrdersPurchaseOrderIdDeleteRequest) => ({
        mutationKey: [params.purchaseOrderId],
        mutationFn: () => ScmInstance.purchaseOrdersPurchaseOrderIdDelete(params)
    }),
});