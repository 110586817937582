import { workLogs } from "@/api/workLogs/useWorksLogsQuery";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { MoldDetailForm } from "@/features/mold/form/moldDetailForm";
import { RoutingDetailForm } from "@/features/routing/components/form/RoutingDetailForm";
import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { WorkView } from "@/features/work/WorkView/WorkView";
import styled from "@emotion/styled";
import { Table, Text, Title } from "@mantine/core";
import { WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { Tbody, Td, Th, Thead, Tr } from "../forward/components/forwardSummary";
import { useLotTrackingState } from "../hook/useLotTrackingState";
import { TableContainer } from "./ledgerTracking";

const Detail = Object.assign(DetailHeader, {
    Link: DetailLink,
    LinkWrapper: DetailLinkWrapper,
    Badge: DetailBadge,
})



export const LotTrackingWorkHistoryContent = () => {
    const state = useLotTrackingState();
    const { openModal } = useModal();

    const { data: workLog } = useQuery({
        ...workLogs.find({
            query: {
                $and: [
                    { productionPlanId: { $eq: state.productionPlan?.productionPlanId } },
                    { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.CONSUME } }
                ]
            },
            populate: ["itemData", "lotData", "workData"]
        }),
        enabled: !!state.productionPlan
    })

    return (
        <Container>
            <Title p={8} order={3}>생산 계획별 작업 지시 작업 기록</Title>
            <Text
                fz={12}
                px={8}
            >
                각 라우팅에 투입된 원부자재의 로트를 추적합니다.
            </Text>
            <TableContainer>
                <Table>
                    <Thead backgroundColor="var(--green-0, #EBFBEE)">
                        <Tr>
                            <Th flex={1}>작업지시번호</Th>
                            <Th flex={1}>라우팅 코드</Th>
                            <Th flex={1}>공정명</Th>
                            <Th flex={1}>공정 시퀀스</Th>
                            <Th flex={1}>라우팅 타입</Th>
                            <Th flex={1}>소모시점</Th>
                            <Th flex={1}>투입 자재</Th>
                            <Th flex={1}>투입 로트</Th>
                            <Th flex={1}>금형코드</Th>
                            {/* <Th flex={1}>금형시리얼</Th> */}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {workLog?.data?.map((row, index) => {
                            return (
                                <Tr key={index}>
                                    <Td flex={1} style={{ textAlign: "right" }}>
                                        <Detail.Link
                                            onClick={() => openModal(
                                                <WorkView workId={row.workId} />,
                                                "",
                                                ""
                                            )}
                                        >{row.workData?.id}</Detail.Link>
                                    </Td>
                                    <Td flex={1}>
                                        <Detail.LinkWrapper>
                                            <Detail.Link
                                                onClick={() => openModal(
                                                    <RoutingDetailForm routingCode={row.workData?.routingCode} />,
                                                    "",
                                                    ""
                                                )}
                                            >
                                                {row.workData?.routingCode}
                                            </Detail.Link>
                                        </Detail.LinkWrapper>
                                    </Td>
                                    <Td flex={1}>
                                        {row.workData?.routingData?.operation?.name}
                                    </Td>
                                    <Td flex={1} style={{ textAlign: "right" }}>
                                        {row.workData?.routingData?.seq}
                                    </Td>
                                    <Td flex={1}>
                                        {row.workData?.routingData?.routingType}
                                    </Td>
                                    <Td flex={1}>
                                        {row.workData?.routingData?.consumeType}
                                    </Td>
                                    <Td flex={1}>
                                        <Detail.LinkWrapper>
                                            <Detail.Link
                                                onClick={() => openModal(
                                                    <ItemsDetailForm itemCode={row.itemData?.code} />,
                                                    "",
                                                    ""
                                                )}
                                            >
                                                {row.itemData?.code}
                                            </Detail.Link>
                                        </Detail.LinkWrapper>
                                    </Td>
                                    <Td flex={1}>
                                        {row.lotData?.name}
                                    </Td>
                                    <Td flex={1}>
                                        <Detail.LinkWrapper>
                                            <Detail.Link
                                                onClick={() => openModal(
                                                    <MoldDetailForm moldCode={row.workData?.routingData?.moldCode} />,
                                                    "",
                                                    ""
                                                )}
                                            >
                                                {row.workData?.routingData?.moldCode}
                                            </Detail.Link>
                                        </Detail.LinkWrapper>
                                    </Td>
                                    {/* <Td flex={1}>
                                        {row.quantity}
                                    </Td> */}
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    max-height: 334px;
    padding: 8px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    background: var(--green-0, #EBFBEE);
    overflow: auto;
`;