
import { Button, ButtonProps } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

interface SaveButtonProps<T> extends ButtonProps {
    form?: UseFormReturnType<T>;
    onSubmit?: (values?: T) => void;
    children?: React.ReactNode;
}

export const SaveButton = <T,>({ form, onSubmit, children, ...etcProps }: SaveButtonProps<T>) => {

    const handleClick = () => {
        if (form && onSubmit) {
            form.onSubmit(onSubmit)();
        } else if (onSubmit) {
            onSubmit();
        }
    };

    return (
        <Button
            onClick={handleClick}
            color="blue"
            {...etcProps}
        >
            {children}
        </Button>
    );
};