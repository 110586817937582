import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { ProductionPlanViewForm } from "@/features/productionPlan/view";
import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import timeUtil from "@/utils/timeUtil";
import { Flex, Radio, Table, Text, Title } from "@mantine/core";
import { WorkLogsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { TableContainer } from "../../components/ledgerTracking";
import { Container, Tbody, Td, Th, Thead, Tr } from "../../forward/components/forwardSummary";
import { useLotTrackingActions } from "../../hook/useLotTrackingActions";
import { useLotTrackingState } from "../../hook/useLotTrackingState";

const Detail = Object.assign(DetailHeader, {
    Link: DetailLink,
    LinkWrapper: DetailLinkWrapper,
    Badge: DetailBadge,
})

interface LotTrackingSummaryContentProps {
    summaryData?: (AxiosResponse<WorkLogsGet200ResponseRowsInner[], any> | undefined)[]
}

export const LotTrackingReverceSummaryContent = (params: LotTrackingSummaryContentProps) => {
    const { summaryData } = params

    const { openModal } = useModal();
    const navigage = useNavigate();
    const actions = useLotTrackingActions();
    const state = useLotTrackingState();
    const item = Array.from(
        new Set(
            summaryData
                ?.flatMap(response => response?.data ?? [])
                ?.map(row => row)
        )
    );

    return (
        <Container>
            <Title p={8} order={3}>요약</Title>
            <Text
                fz={12}
                px={8}
            >
                각 라우팅에 투입된 원부자재의 로트를 추적합니다.
            </Text>
            <TableContainer>
                <Table>
                    <Thead backgroundColor="var(--pink-0, #FFF0F6)">
                        <Tr>
                            <Th flex={0.1}></Th>
                            <Th flex={1}>품목</Th>
                            <Th flex={1}>로트명</Th>
                            <Th flex={1}>로트 유효기한</Th>
                            <Th flex={1}>생산계획</Th>
                            <Th flex={1}></Th>
                        </Tr>
                    </Thead>
                    {item.length === 0 ? (
                        <Flex justify={'center'} align={'center'}>
                            <Text px={8}>데이터가 없습니다</Text>
                        </Flex>
                    ) : (
                        <Tbody>
                            {item?.filter((value, index, self) => index === self.findIndex((t) => t.productionPlanId === value.productionPlanId))?.map((item, index) => {
                                const handleRadioClick = (planId: WorkLogsGet200ResponseRowsInner) => {
                                    actions.setProductionPlan(planId)
                                }
                                return (
                                    <Tr key={index}>
                                        <Td flex={0.1}>
                                            <Flex justify={'center'} align={'center'} pt="md">
                                                <Radio
                                                    name="productionPlan"
                                                    value={item.productionPlanId}
                                                    checked={state.productionPlan?.productionPlanId === item.productionPlanId}
                                                    onChange={() => handleRadioClick(item)}
                                                />
                                            </Flex>
                                        </Td>
                                        <Td flex={1}>
                                            <Detail.LinkWrapper>
                                                <Detail.Badge>품목</Detail.Badge>
                                                <Detail.Link
                                                    onClick={() => openModal(
                                                        <ItemsDetailForm itemCode={item.itemData?.code} />,
                                                        "",
                                                        ""
                                                    )}
                                                >
                                                    {item.itemData?.name}
                                                </Detail.Link>
                                            </Detail.LinkWrapper>
                                        </Td>
                                        <Td flex={1}>
                                            {item.lotData?.name ?? '-'}
                                        </Td>
                                        <Td flex={1}>
                                            {item.lotData?.expiration === null ? '-' : timeUtil(item.lotData?.expiration ?? '')}
                                        </Td>
                                        <Td flex={1}>
                                            <Detail.LinkWrapper>
                                                <Detail.Badge>생산계획</Detail.Badge>
                                                <Detail.Link
                                                    onClick={() => openModal(
                                                        <ProductionPlanViewForm ProductionPlanId={item?.productionPlanId} />,
                                                        "",
                                                        ""
                                                    )}
                                                >
                                                    {item.productionPlanId}
                                                </Detail.Link>
                                            </Detail.LinkWrapper>
                                        </Td>
                                        <Td flex={1}>
                                            <Detail.LinkWrapper>
                                                <Detail.Link
                                                    onClick={() => {
                                                        navigage('/lotForward', {
                                                            state : { value : 'click' }
                                                        })
                                                        actions.setItemCodeValue(item.itemData?.code ?? '');
                                                        actions.setLotId(item.lotId?.toString() ?? '');
                                                    }}>
                                                    정방향 로트 추적 바로가기
                                                </Detail.Link>
                                            </Detail.LinkWrapper>
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    )}
                </Table>
            </TableContainer>
        </Container>
    )
}
