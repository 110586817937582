import { useStandardLayout } from "@/features/standardLayout/Context";
import { handleKeyDown } from "@/utils/event";
import { Button, Flex, Select, TextInput } from "@mantine/core";
import { useEffect, useRef, useState } from "react";

const pageSizeOptions = [
    { value: '5', label: "5개씩" },
    { value: '10', label: "10개씩" },
    { value: '15', label: "15개씩" },
    { value: '20', label: "20개씩" },
]

export const SearchBox = () => {
    const { setPage, pageSize, setPageSize, setSelectedRows, searchFields, setSearchFields, search, setSearch, searchFieldsHeader } = useStandardLayout();

    const [pageSizeState, setPageSizeState] = useState(pageSize);
    const [searchFieldsState, setSearchFieldsState] = useState(searchFields);

    const searchRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (searchRef.current) {
            searchRef.current.value = search;
        }
        setPageSizeState(pageSize);
        setSearchFieldsState(searchFields);
    }, [pageSize, search, searchFields]);

    const onSubmit = () => {
        setPage(1); // 검색어 변경 시 항상 페이지를 1로 초기화
        setSelectedRows((): Set<any> => new Set()); // 검색어 변경 시 항상 선택된 행 초기화
        setPageSize(pageSizeState);
        setSearchFields(searchFieldsState);
        setSearch(searchRef.current?.value || "");
    }

    return (
        <Flex gap="sm">
            <Select
                data={pageSizeOptions}
                value={pageSizeState.toString()}
                onChange={(e) => setPageSizeState(Number(e))}
            />
            <Select
                data={searchFieldsHeader.filter((item) => item.category === "text" && !item.isEnum)}
                value={searchFieldsState.toString()}
                placeholder="통합 검색"
                onChange={(e) => setSearchFieldsState(e ? [e] : [])}
            />
            <TextInput
                datatype="text"
                defaultValue={search}
                placeholder="검색어를 입력하세요"
                ref={searchRef}
                onKeyDown={(e) => handleKeyDown(e, onSubmit)}
            />
            <Button onClick={() => onSubmit()}>검색</Button>
        </Flex>
    )
}