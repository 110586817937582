import { mutateInventories } from "@/api/inventories/useInventoriesQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { OrderItemType } from "@/features/order/form/orderItemsIncomingForm";
import customAlert from "@/features/ui/alert/alert";
import { DefaultApiWmsIncomingPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Dispatch, SetStateAction } from "react";

export interface OrderItemsIncomingProps {
    setLoading: Dispatch<SetStateAction<boolean>>;
}

export const useOrderItemsIncoming = (params : OrderItemsIncomingProps) => {

    const queryClient = useQueryClient();
    const { setLoading } = params;
    const { closeModal } = useModal();
    const { mutate: incomingMutate, isLoading } = useMutation(
        (params: DefaultApiWmsIncomingPostRequest) => mutateInventories.incoming(params).mutationFn(undefined),
        {
            onMutate: () => {
                setLoading(true)
            },
            onSuccess: () => {
                queryClient.invalidateQueries(["inventories"]);
                queryClient.invalidateQueries(["purchaseOrderItems"]);
                queryClient.invalidateQueries(["purchaseOrders"]);
                customAlert("입고에 성공하였습니다.", "입고 성공", "green");
            },
            onError: (error) => {
                customAlert("입고에 실패하였습니다.", "입고 실패", "red");
            },
            onSettled: () => {
                setLoading(false);
            }
        }
    );
    
    const orderItemsSubmit = (item: OrderItemType) => {
        incomingMutate({
            workLogsWorkLogIdCancelStartPut200ResponseCancelConsumeInner: {
                itemCode: item.itemCode ?? '',
                locationCode: item.targetLocationCode ?? '',
                quantity: item.quantity as string,
                lotId: item.lotId as number,
                lotInfo: item.lotInfo,
                purchaseOrderItemId: item.id,
                actionFrom: "WEB",
            },
        });
    };

    return {
        orderItemsSubmit,
        setLoading,
        isLoading
    };
};
