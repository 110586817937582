import { mutateLots } from "@/api/lots/useLotsQuery";
import customAlert from "@/features/ui/alert/alert";
import { handleErrorResponse } from "@/utils/errorMessage";
import { DefaultApiLotsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useLotMutations = () => {
    const queryClient = useQueryClient();

    const postLotsMutate = useMutation(
        (params: DefaultApiLotsPostRequest) => mutateLots.create(params).mutationFn(undefined),
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries(["lots"]);
                return data?.data; // 성공 시 데이터 반환
            },
            onError: (error) => {
                customAlert(
                    handleErrorResponse({ error, errorContext: "로트 생성" }),
                    "로트 생성 실패",
                    "red"
                );
            },
        }
    );

    return { postLotsMutate };
};
