import { ledgers } from "@/api/ledgers/useLedgersQuery";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { ProductionPlanViewForm } from "@/features/productionPlan/view";
import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import DatetimeUtil from "@/utils/dateTimeUtil";
import { setWmsSatus, wmsStatusColor } from "@/utils/wmsStatus";
import styled from "@emotion/styled";
import { Table, Text, Title } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { Tbody, Td, Th, Thead, Tr } from "../forward/components/forwardSummary";
import { useLotTrackingState } from "../hook/useLotTrackingState";

const Detail = Object.assign(DetailHeader, {
    Link: DetailLink,
    LinkWrapper: DetailLinkWrapper,
    Badge: DetailBadge,
})

interface LotTrackingAdditional {
    productionPlanId: number
}

export const LotTrackingLedgerTrackingContent = () => {
    const { openModal } = useModal();
    const state = useLotTrackingState();

    const { data } = useQuery({
        ...ledgers.find({
            query: {
                $and: [
                    { lotId: { $eq: state.productionPlan?.lotId } }
                ]
            },
            populate: ["name", "wmsLogData"],
        })
    })

    return (
        <Container>
            <Title p={8} order={3}>재고 추적</Title>
            <Text
                fz={12}
                px={8}
            >
                품목이 생산되고 출고된 기록을 보여줍니다.
            </Text>
            <TableContainer>
                <Table>
                    <Thead backgroundColor="var(--indigo-0, #EDF2FF)">
                        <Tr>
                            <Th flex={1}>일시</Th>
                            <Th flex={1}>타입</Th>
                            <Th flex={1}>수량</Th>
                            <Th flex={1}>생산계획번호</Th>
                            <Th flex={1}>로케이션</Th>
                            <Th flex={1}>작업자</Th>
                            <Th flex={1}>비고</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data?.data?.rows?.map((row, index) => {
                            return (
                                <Tr key={index}>
                                    <Td flex={1}>
                                        {DatetimeUtil(row.createdAt ?? '')}
                                    </Td>
                                    <Td flex={1}>
                                        <Detail.Badge color={wmsStatusColor[(row.type) as keyof typeof wmsStatusColor]}>
                                            {setWmsSatus(row.type ?? '')}
                                        </Detail.Badge>
                                    </Td>
                                    <Td flex={1} style={{ textAlign: "right" }}>
                                        {row.quantity} {row.itemData?.unit_text}
                                    </Td>
                                    <Td flex={1}>
                                        <Detail.Link
                                            justify={'flex-end'}
                                            onClick={() => openModal(
                                                <ProductionPlanViewForm ProductionPlanId={(row.additional as LotTrackingAdditional)?.productionPlanId} />,
                                                "",
                                                ""
                                            )}
                                        >{(row.additional as LotTrackingAdditional)?.productionPlanId}</Detail.Link>
                                    </Td>
                                    <Td flex={1}>
                                        <Detail.Link
                                            onClick={() => openModal(
                                                <LocationDetailForm locationCode={row.locationData?.code} />,
                                                "",
                                                ""
                                            )}
                                        >{row.locationData?.name}</Detail.Link>
                                    </Td>
                                    <Td flex={1}>
                                        <Detail.LinkWrapper>
                                            <Detail.Badge>작업자</Detail.Badge>
                                            <Detail.Link
                                            // onClick={() => openModal(
                                            //     <UsersDetailForm UserCode="" />,
                                            //     "",
                                            //     ""
                                            // )}
                                            >{row.name}</Detail.Link>
                                        </Detail.LinkWrapper>
                                    </Td>
                                    <Td flex={1}>
                                        {row.type === "OUTGOING" && row.purchaseOrderItemId ? `고객사 출고건 ${row.purchaseOrderItemId}` : row.type === "INCOMING" && row.purchaseOrderItemId ? `고객사 입고건 ${row.purchaseOrderItemId}` : ''}
                                    </Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    max-height: 334px; /* 최대 높이를 설정하여 3개 이상의 Tr 요소가 있을 때 스크롤이 생기도록 함 */
    padding: 8px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    background: var(--indigo-0, #EDF2FF);
    overflow: auto; /* 높이가 초과될 경우 스크롤을 활성화 */
`;

export const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto; /* 테이블 컨테이너에 스크롤을 추가 */
`;
