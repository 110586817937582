import { mutateLots } from "@/api/lots/useLotsQuery";
import { DefaultApiLotsGetLotNamePostRequest, DefaultApiLotsPostRequest, ItemsGet200ResponseRowsInnerLotsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

export const useCreateLot = () => {

    const [createdLotInform, setCreatedLotInform] = useState<ItemsGet200ResponseRowsInnerLotsInner[]>([]);

    const queryClient = useQueryClient();

    const { mutate: getLotNameMutate } = useMutation(
        (params: DefaultApiLotsGetLotNamePostRequest) =>
            mutateLots.getLotName(params).mutationFn(undefined),
    )

    const { mutate: postLotsMutate } = useMutation(
        (params: DefaultApiLotsPostRequest) =>
            mutateLots.create(params).mutationFn(undefined),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["lots"]);
            }
        }
    );

    const createLot = async (itemCodeList: string[]) => {
        try {

            const lotNameList: string[] = await new Promise((resolve, reject) => {
                getLotNameMutate(
                    {
                        lotsGetLotNamePostRequest: {
                            itemCode: itemCodeList
                        },
                    },
                    {
                        onSuccess: (data) => {
                            const lotName = data.data;
                            resolve(lotName);
                        },
                        onError: (error) => {
                            console.error("LOT 이름 가져오기 중 오류 발생:", error);
                            reject(error);
                        },
                    })
            })

            const newLotsInform: ItemsGet200ResponseRowsInnerLotsInner[] = [];

            for (let i = 0; i < lotNameList.length; i++) {

                const lotName = lotNameList[i];
                const itemCode = itemCodeList[i];

                const result: ItemsGet200ResponseRowsInnerLotsInner = await new Promise((resolve, reject) => {
                    postLotsMutate(
                        {
                            lotsGetRequest: {
                                name: lotName,
                                itemCode: itemCode,
                            },
                        },
                        {
                            onSuccess: (data) => {
                                console.log(`onSuccess 실행: ${lotName}`, data);
                                resolve(data.data);
                            },
                            onError: (error) => {
                                console.error(`onError 발생: ${lotName}`, error);
                                reject(null);
                            },
                        }
                    );
                });
                newLotsInform.push(result);
            }

            setCreatedLotInform(newLotsInform)

        } catch (error) {
            console.error("로트 생성 중 오류 발생:", error);
            throw error;
        }
    };


    return { createLot, createdLotInform };
}